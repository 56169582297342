<template>
  <div>
    <slot name="top" />
    <div v-for="(elem, index) in usedMethods" :key="elem.id">
      <div class="d-flex flex-row">
        <div>
          <v-checkbox
            v-model="states[index]"
            :label="elem.title"
            @change="doChange()"
            v-bind="$attrs"
            hide-details
            :readonly="elem.calculate || readonly"
            :error="error"
          >
            <template #append>
              <div v-if="!readonly" class="method_annotations">
                <v-btn
                  v-if="
                    states[index] && annotationsStates.indexOf(index) === -1
                  "
                  x-small
                  @click="openAnnotation(index)"
                  title="Добавить пояснение к методу"
                  icon
                >
                  <v-icon>mdi-pencil-plus</v-icon></v-btn
                >
                <v-btn
                  v-else-if="
                    states[index] && annotationsStates.indexOf(index) !== -1
                  "
                  x-small
                  @click="closeAnnotation(index)"
                  icon
                >
                  <v-icon>mdi-minus</v-icon></v-btn
                >
              </div>
            </template>
          </v-checkbox>
        </div>
      </div>
      <AutoGrowTextEditor
        v-if="annotationsStates.indexOf(index) !== -1"
        :readonly="readonly"
        v-bind="$attrs"
        label="Пояснение"
        v-model="annotations[index]"
        @input="doChange()"
        @blur="doBlur()"
        hide-details
        rows="1"
        class="px-8"
      />
    </div>
    <v-divider v-if="error" class="mt-3" />
    <span v-if="error" class="error--text text-caption">
      {{ errorText }}
    </span>
    <slot name="bottom" />
  </div>
</template>

<script>
import { copyObject } from '@/lib/objects'
import AutoGrowTextEditor from '@/components/editors/AutoGrowTextEditor'
import {
  EX_KEI_ANNOTATIONS_DEFAULT,
  EX_KEI_METHOD,
  EX_KEI_METHOD_CHECK,
  EX_KEI_METHOD_DEFAULT,
} from '@/components/expAvbKei/const'

export default {
  name: 'KeiMethodEditor',
  inheritAttrs: true,
  components: { AutoGrowTextEditor },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String || null,
      default: '',
    },
  },
  data() {
    return {
      usedMethods: EX_KEI_METHOD,
      states: [], // массив галок
      annotations: [], // массив аннотаций
      annotationsStates: [], //массив открытых аннотаций
    }
  },
  created() {
    // по создании компонента копируем то, что пришло снаружи (массив галок)
    this.init(this.value)
  },
  methods: {
    init(newValue) {
      this.states = copyObject(newValue.items) || EX_KEI_METHOD_DEFAULT()
      this.annotations =
        copyObject(newValue.annotations) || EX_KEI_ANNOTATIONS_DEFAULT()

      //Открыты не пустые аннотаций при активном методе
      this.annotations.forEach((item, idx) => {
        if (item && this.states[idx]) this.annotationsStates.push(idx)
      })
    },
    doChange() {
      const res = copyObject(this.value)

      res.items = EX_KEI_METHOD_CHECK(this.states)
      res.annotations = this.annotations
      this.$emit('input', res)
    },
    doBlur() {
      this.$emit('blur')
    },
    openAnnotation(idx) {
      this.annotationsStates.push(idx)
    },
    closeAnnotation(idx) {
      const delIdx = this.annotationsStates.findIndex(val => val === idx)
      this.annotationsStates.splice(delIdx, 1)
    },
  },
  watch: {
    // следить за value, и вызвать метод init, это работает для COMPARE
    value: 'init',
  },
}
</script>

<style scoped>
.method_annotations {
  margin-top: 2px;
}
</style>
